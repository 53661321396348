<template>
	<div class="contact__container" id="contacts">	
		<div class="contact__map">
			<h1 class="catalog__h1">Контакты</h1>
			<div class="catalog__block">
				<p @click="goTo(1)"><img src="@/assets/icons/tel.svg"> +7-964-888-99-05</p>
				<p @click="goTo(2)"><img src="@/assets/icons/vk.svg"> druzhbamebel</p>
				<p @click="goTo(3)"><img src="@/assets/icons/tellegram.svg"> druzhbamebel</p>
				<p style="align-items: flex-start;"><img src="@/assets/icons/location.svg">Адрес: г. Астрахань <br>ул. Николая Островского 148е</p>
				<p style="align-items: flex-start;"><img src="@/assets/icons/clock.svg">Работаем ежедневно: <br>С 10:00 - 18:00</p>
			</div>
		</div>
		<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aefe400293d2f7bed9910379bbf9376f637e8620d8b630ddc24c7760999b41337&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
	</div>
</template>


<script>
export default {
	methods: {
		goTo(ind) {
			if(ind === 1) {
				window.open('tel:+7-964-888-99-05');
			}
			else if(ind === 2) {
				
				window.open('https://vk.com/druzhbamebel', '_blank');
			}
			else {
				console.log('zxc')
				window.open('https://t.me/druzhbamebel', '_blank');
			}
		}
	}
}
</script>

<style scoped>
img {
	width: 1.5rem;
}
p {
	display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 300;
}
</style>

<style>
.button__text {
	display: none !important
}
.contact__container {
	margin-bottom: 5rem;
    position: relative;
}
.contact__map {
    position: absolute;
    background-color: white;
    margin: 0.5rem;
    height: calc(100% - 1.4rem);
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 2rem;
}
.catalog__h1 {
	margin: 0
}
.catalog__block {
	text-align: left;
}
</style>