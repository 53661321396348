<template>
  <div class="home">
    <Swipe/>
    <About/>
    <Catalog/>
    <Coop/>
  </div>
</template>

<script>

import Swipe from '@/components/Swipe.vue'
import About from '@/components/About.vue'
import Catalog from '@/components/Catalog.vue'
import Coop from '@/components/Coop.vue'

export default {
  name: 'HomeView',
  components: {
    Swipe, About, Catalog, Coop
  }
}
</script>

<style>
.home {


}
</style>