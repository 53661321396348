<template>
  <div id="app">
    <nav>
      <div class="burger__main" v-if="showBurger" @click="showMenu = !showMenu">
        <div class="burger__container">
          <div class="burger__stick" v-for="it in 3">
          </div>
        </div>
        <img class="logo__svg burger__logo" src="@/assets/icons/logo.png"/>
        <p class="nav-phone__router burger__phone" @click="callTo">+7-964-888-99-05</p>
      </div>
      <transition>
        <div class="container__block animeshka" :class="{'menu__block': showMenu}" v-if="!showBurger || showMenu">
          <div class="nav__flex" :class="{'burger-opened__nav': showMenu}">
            <img v-if="!showMenu" class="logo__svg" src="@/assets/icons/logo.png"/>
            <a class="nav-a__router" @click="scrollTo('catalog')">Каталог</a>
            <a class="nav-a__router" @click="scrollTo('about')">О нас</a>
            <a class="nav-a__router" @click="scrollTo('contacts')">Контакты</a>
            <a class="nav-a__router" @click="scrollTo('coop')">Сотрудничество</a>
            <p v-if="!showMenu" class="nav-phone__router" @click="callTo">+7-964-888-99-05</p>
          </div>
        </div>
      </transition>
    </nav>
    <router-view/>
  </div>
</template>


<script>
export default {
  data () {
    return {
      showBurger: false,
      showMenu:false
    }
  },
  mounted() {
    let that = this
    window.addEventListener('resize', function(event) {
      that.widthChange()
    })
    this.widthChange()
  },
  unmounted() {
  },
  methods: {
    widthChange() {
      console.log('zxcs', window.screen.width);
      if(window.screen.width < 768) {
        console.log("true")
        this.showBurger = true
      }
      else {
        this.showBurger = false
      }
    },
    callTo () {
      window.open('tel:+7-964-888-99-05');
    },
    scrollTo (id) {
      const element = document.getElementById(id);
      console.log(element)
      element.scrollIntoView({ behavior: "smooth"});
    }
  }
}

</script>

<style>

.menu__block {
  height: 11.5rem;
}
.animeshka {
  transition: opacity .5s ease;
}
.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.burger__main {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 0rem;
  margin-right: 0rem;
  justify-content: space-between;
}

.burger__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5rem;
}
.burger__stick {
  width: 2.2rem;
  height: 0.2rem;
  background: white;
  border-radius: 1rem;
}
.nav__flex {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.burger-opened__nav {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #152b32;
  z-index: 2;
  position: relative;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1rem;
  display: flex;
}
.logo__svg {
  width: 4rem;
  height: 4rem;
  padding: 0;
  margin-right: 2rem;
}
.nav-phone__router {
  margin: 0;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
body {
  padding: 0;
  margin: 0
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

* {
  box-sizing: border-box;
}

.container__block {

  margin: auto;
  width: 100%;
}

nav {
  padding: 1rem;
  text-align: left;
  background-color: rgb(21 43 50);
  height: 6rem;
  -webkit-box-shadow: 0px 0rem 1rem 0.1rem rgb(21 43 50);
  box-shadow: 0px 0rem 1rem 0.1rem rgb(21 43 50);
  position: relative;
  z-index: 3;
  font-size: 1.2rem;
}

nav a {
  font-weight: bold;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

nav a:hover {
  color: white;
  opacity: 0.7;
}

.button-1 {
    border: none;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #152b32;
    color: white;
    font-size: 1.21rem;
    font-weight: bold;  
    cursor: pointer;
}

.nav-phone__router {
  margin-left: auto;
}

html {
  font-size: 16px;
}
.burger__phone {
  margin-left: 0;
  font-size: 0.8rem;
}
.burger__logo {
  margin-right: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 3.5rem;
  height: 3.5rem;
}
@media (max-width: 768px) {
  .nav__flex {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #152b32;
    z-index: 2;
    position: relative;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem;
    display: flex;
    margin-top: 1rem;
  }
  nav {
    height: 4.5rem;
  }
}
</style>
