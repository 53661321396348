<template>
<div class="swiper__main" id="catalog">
	<div class="container__block">
		<div class="swiper__flex">
			<div class="swiper__container">
			    <VueSlickCarousel :arrows="false" :dots="false" :autoplay="true" :autoplaySpeed="autoplaySpeed">
			      <div class="card">
			      	<img class="divan__img" src="@/assets/images/divan-1.jpg">
			      </div>
			      <div class="card">
			      	<img class="divan__img" src="@/assets/images/divan-2.jpg">
			      </div>
			    </VueSlickCarousel>
			</div>
			<div class="swiper-2__container">
			    <VueSlickCarousel :arrows="true" :dots="false">
			      <div class="card" v-for="item in cards">
			      	<Card :item="item"/>
			      </div>
			    </VueSlickCarousel>
			</div>
		</div>
	</div>
</div>
</template>

<script>

  import VueSlickCarousel from 'vue-slick-carousel'

 import Card from '@/components/Card.vue'

export default {
	components: {
		VueSlickCarousel, Card
	},
	data () {
		return {
			autoplaySpeed: 5000,
			divans: [
				{
					title: '',
					url: './../assets/images/divan-1.jpeg'
				}
			],
			cards: [
				{
					title: 'Скидка',
					img: require('../assets/images/divan-1.png'),
					name: 'Парус',
					price: '28500',
					saleCount: '-15%',
					sale: '24500',
					url: 'https://www.avito.ru/astrahan/mebel_i_interer/divan_raskladnoy_ot_proizvoditelya_4164280595?utm_campaign=native&utm_medium=item_page_android&utm_source=soc_sharing'
				},
				{
					title: 'Товар месяца',
					img: require('../assets/images/divan-2.png'),
					name: 'Эллада',
					price: '28500',
					saleCount: '-15%',
					sale: '24500',
					url: 'https://www.avito.ru/astrahan/mebel_i_interer/divan_ellada_4164445842?utm_campaign=native&utm_medium=item_page_android&utm_source=soc_sharing'
				},
				{
					title: 'Классика',
					img: require('../assets/images/divan-3.png'),
					name: 'Плутон',
					price: '28500',
					saleCount: '-15%',
					sale: '24500',
					url: 'https://www.avito.ru/astrahan/mebel_i_interer/divan_pryamoy_raskladnoy_4163664053?utm_campaign=native&utm_medium=item_page_android&utm_source=soc_sharing'
				},
				{
					title: 'Классика',
					img: require('../assets/images/divan-4.png'),
					name: 'Плутон',
					price: '28500',
					saleCount: '-15%',
					sale: '24500',
					url: 'https://www.avito.ru/astrahan/mebel_i_interer/divan_pryamoy_raskladnoy_4163664053?utm_campaign=native&utm_medium=item_page_android&utm_source=soc_sharing'
				}





			]
		}
	}
}
</script>

<style>
.swiper__flex {
	display: flex;
	flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}


.divan__img {
	display: block;
	width: 100%;
	object-fit: cover;
	position: absolute;
	top: 50%;
	object-position: center center;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
  min-height: 27rem;
}
.swiper__main {
  background-color: #FBEEC1;
  padding: 2rem;
}
.swiper__container {
	width: calc(100% - 22rem);
	overflow: hidden;
	border-radius: 1rem;
}

.swiper-2__container {
	width: 20rem;
}
.card {
	width: 100%;
    display: inline-block;
    background-color: white;
    height: 27rem;
    border-radius: 1rem;
    position: relative;
}
.slick-prev {
	left: 0.5rem !important;
    z-index: 1;
    filter: invert(1);
}
.slick-next {
	right: 0.5rem !important;
    z-index: 1;
    filter: invert(1);
}

@media (max-width: 765px) {
	.swiper__main {
		display: flex;
    flex-direction: column;
    gap: 2rem;
	}
	.swiper__container, .swiper-2__container {
		width: 100%
	}
}
</style>