import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

require('vue-slick-carousel/dist/vue-slick-carousel.css')

  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
