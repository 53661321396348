<template>
<div class="card__container">

	<div class="card-info__container">
		<h1 class="card__h1">{{item.title}}</h1>
		<p class="card__p">{{item.name}}</p>
		<img class="card__img" :src="item.img"/>
		<div class="card__price">
			<p class="card__p card-sale__p">{{item.sale}}</p>
			<div class="card-sale-counter__block">{{item.saleCount}}</div>
			<p class="card__p card-price__p">{{item.price}}</p>
		</div>

		<button @click="goToUrl()" class="button-1">Подробнее</button>

	</div>
</div>
</template>

<script>
export default {
	props: ['item'],
	methods: {
		goToUrl () {
			console.log(this.item.url)
			window.open(this.item.url, '_blank').focus();
		}
	}
}

</script>

<style>
.card-sale-counter__block {
	background-color: #fff174;
    padding: 0.3rem;
    border-radius: 0.3rem;
}
.card-sale__p {
	font-weight: bold;
}
.card-price__p {
	text-decoration: line-through;
    opacity: 0.5;

}
.card__price {
	display: flex;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 1rem;
}
.card-info__container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-between;
	align-items: center;
	height: calc(100% - 2rem);
}
.card__h1 {
	margin: 0
}
.card__p {
	margin: 0
}
.card__img {
	user-select: none;
	pointer-events: none;
	width: 15rem;
    height: 10rem;
    object-fit: cover;
}
.card__container {
	padding: 1rem;
    height: 100%;
}
</style>